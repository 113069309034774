<div *ngIf="header" class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">{{ title }}</h4>
    <button type="button" class="close" (click)="modal.dismiss('Annuler')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
    <ng-content select="[body]"></ng-content>
</div>
<div *ngIf="footer" class="modal-footer">
    <ng-content select="[footer]"></ng-content>
</div>