import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreAlertBarComponent } from './core-alert-bar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [CommonModule,NgbModule],
  declarations: [CoreAlertBarComponent],
  exports: [CoreAlertBarComponent]
})
export class CoreAlertBarModule { }