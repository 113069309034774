import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreAlertBarModule, CoreModalModule, CoreSidebarModule, CoreSpinnerModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { DashboardModule } from 'app/pages/dashboard/dashboard.module';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { ColumnChangesService, DimensionsHelper, ScrollbarHelper } from '@swimlane/ngx-datatable';
import { AuthGuard, ErrorInterceptor, JwtInterceptor } from './auth/helpers';
import { JwtModule } from '@auth0/angular-jwt';

export function tokenGetter() {
  return JSON.parse(localStorage.getItem('user')).token;
}

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
    
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return params.key
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot({
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
      //useDefaultLang: false
    }),
    NgxMaskModule.forRoot(),
    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CoreModalModule,
    CoreSpinnerModule,
    CoreAlertBarModule,
    
    // App modules
    LayoutModule,
    DashboardModule,
    JwtModule.forRoot(
      {
        config: {
          tokenGetter : tokenGetter,
          allowedDomains:[],
          disallowedRoutes: []
        }
      }
    ),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' }, // Set the locale here
    DecimalPipe,
    ScrollbarHelper,
    DimensionsHelper,
    ColumnChangesService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],

  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeEn, 'en');
  }
  
}
