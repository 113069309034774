import { Injectable } from "@angular/core";
import { ToastrConfig, ToastrService } from "ngx-toastr";
import Swal from 'sweetalert2';

const toastConfig : any = {
    closeButton  : true,
    timeOut : 5000,
    progressBar : true,
    progressAnimation : 'increasing',
    enableHtml : true
}

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(
        private _toastrService: ToastrService
    ) { }

    public toastSuccess(message : string){
        this._toastrService.success('Succès', message, toastConfig)
    }

    public toastError(message : string){
        this._toastrService.error('Erreur', message, toastConfig)
    }

    public successAlert(text: string) {
        return Swal.fire({
            title: 'Succès',
            text: text,
            icon: 'success'
        });
    }

    public errorAlert(text: string) {
        return Swal.fire({
            title: 'Erreur',
            text: text,
            icon: 'error'
        });
    }

    public confirmAlert(text: string) {
        return Swal.fire({
            text: text,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger ml-1'
            }
        });
    }

}