import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'MENU.DASHBOARD',
    type: 'item',
    icon: 'home',
    url: 'dashboard',
    role: []
  },
  {
    id: 'settings',
    title: 'Paramètres',
    translate: 'MENU.SETTINGS_SECTION',
    type: 'section',
    role: [],
    children: [
      {
        id: 'geolocalisation',
        title: 'Géolocalisation',
        translate: 'MENU.MAPS',
        type: 'collapsible',
        icon: 'sliders',
        role: [],
        children: [
          {
            id: 'country',
            title: "Pays",
            translate: 'MENU.COUNTRIES',
            type: 'item',
            icon: 'circle',
            url: 'pages/settings/maps/countries',
            role: []
          },
          {
            id: 'city',
            title: 'Villes',
            translate: 'MENU.CITIES',
            type: 'item',
            icon: 'circle',
            url: 'pages/settings/maps/cities',
            role: []
          },
          {
            id: 'district',
            title: 'Quartier',
            translate: 'MENU.DISTRICTS',
            type: 'item',
            icon: 'circle',
            url: 'pages/settings/maps/districts',
            role: []
          }
        ]
      }
      ,
      {
        id: 'services',
          title: 'Services',
          translate: 'MENU.SERVICES',
          type: 'collapsible',
          icon: 'link',
          role: [],
          children: [
          {
            id: 'service-cat',
            title: 'Catégories',
            translate: 'MENU.SERVICE_CATEGORY',
            type: 'item',
            icon: 'circle',
            url: 'pages/settings/services/categories',
            role: []
          },
          {
            id: 'service-sub-cat',
            title: 'Sous Catégories',
            translate: 'MENU.SERVICE_SUB_CATEGORY',
            type: 'item',
            icon: 'circle',
            url: 'pages/settings/services/sub-categories',
            role: []
          },
          {
            id: 'service-unity',
            title: 'Unités',
            translate: 'MENU.SERVICE_UNITY',
            type: 'item',
            icon: 'circle',
            url: 'pages/settings/services/unities',
            role: []
          },
          {
            id: 'coupon',
            title: 'Coupons',
            translate: 'MENU.COUPONS',
            type: 'item',
            icon: 'circle',
            url: 'pages/settings/services/coupons',
            role: []
          }
        ]
      },
      {
        id: 'security',
          title: 'Sécurité',
          translate: 'MENU.SECURITY',
          type: 'collapsible',
          icon: 'lock',
          role: ["USER_READ", "USERTYPE_READ", "USERGROUP_READ"],
          children: [
          // {
          //   id: 'userType',
          //   title: 'Type utilisateur',
          //   translate: 'MENU.USERTYPE',
          //   type: 'item',
          //   icon: 'circle',
          //   url: 'pages/user-type',
          //   role: ["USERTYPE_READ"]
          // },
          {
            id: 'user',
            title: 'Utilisateur',
            translate: 'MENU.USER',
            type: 'item',
            icon: 'circle',
            url: 'pages/security/users',
            role: ["USER_READ"]
          }
        ]
      }
    ]
  },
  {
    id: 'exploitation',
    title: 'Exploitation',
    translate: 'MENU.EXPLOITATION_SECTION',
    type: 'section',
    role: [],
    children: [
      {
        id: 'crm',
        title: 'CRM',
        translate: 'MENU.CRM',
        type: 'collapsible',
        icon: 'shopping-bag',
        role: [],
        children: [
          {
            id: 'client',
            title: 'Client',
            translate: 'MENU.CLIENTS',
            type: 'item',
            icon: 'circle',
            url: 'pages/crm/clients',
            role: []
          },
          {
            id: 'vendor',
            title: 'Vendeurs',
            translate: 'MENU.VENDORS',
            type: 'item',
            icon: 'circle',
            url: 'pages/crm/vendors',
            role: []
          },
          {
            id: 'account_deletion_request',
            title: 'Suppression compte',
            translate: 'MENU.ACCOUNT_DELETION_REQUESTS',
            type: 'item',
            icon: 'circle',
            url: 'pages/crm/account-deletion-requests',
            role: []
          },
          {
            id: 'contact-requests',
            title: 'Demandes contact',
            translate: 'MENU.CONTACT_REQUESTS',
            type: 'item',
            icon: 'circle',
            url: 'pages/crm/contact-requests',
            role: []
          }
        ]
      },
      {
        id: 'bookings',
          title: 'Réservations',
          translate: 'MENU.BOOKINGS',
          type: 'collapsible',
          icon: 'link',
          role: [],
          children: [
            {
              id: 'booking-list',
              title: 'Nouvelle resa',
              translate: 'MENU.BOOKING_CREATE',
              type: 'item',
              icon: 'circle',
              url: 'pages/bookings/create',
              role: []
            },
          {
            id: 'booking-list',
            title: 'Liste des resas',
            translate: 'MENU.BOOKING_LIST',
            type: 'item',
            icon: 'circle',
            url: 'pages/bookings',
            role: []
          },
          {
            id: 'booking-marks',
            title: 'Evaluation resa',
            translate: 'MENU.BOOKING_MARKS',
            type: 'item',
            icon: 'circle',
            url: 'pages/bookings/evaluations',
            role: []
          },
          {
            id: 'booking-requests',
            title: 'Demandes Resa',
            translate: 'MENU.BOOKING_REQUESTS',
            type: 'item',
            icon: 'circle',
            url: 'pages/bookings/requests',
            role: []
          }
        ]
      }
    ]
  },

]
