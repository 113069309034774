import { NgModule } from '@angular/core';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { CoreLoadingContainerComponent } from './core-loading-container.component';

@NgModule({
  declarations: [CoreLoadingContainerComponent],
  imports : [CoreDirectivesModule, NgbModule, CommonModule],
  exports: [CoreLoadingContainerComponent]
})
export class CoreLoadingContainerModule {}