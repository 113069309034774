import { NgModule } from '@angular/core';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { CoreActionComfirmationComponent } from './core-action-confirmation.component';

@NgModule({
  declarations: [CoreActionComfirmationComponent],
  imports : [CoreDirectivesModule, NgbModule, CommonModule],
  exports: [CoreActionComfirmationComponent]
})
export class CareActionConfirmationModule {}