import { Component, OnInit } from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'core-action-confirmation',
  templateUrl: './core-action-confirmation.component.html',
  styleUrls: ['./core-action-confirmation.component.css']
})
export class CoreActionComfirmationComponent implements OnInit {

  public datas: any = null;
  public type : string = ''

  constructor(private modalService: NgbModal, public modal: NgbActiveModal,) { }

  ngOnInit(): void {
  }

  public setDatas(data){
    this.datas = data
    if(this.datas){
      this.type = this.datas.type ? this.datas.type : 'delete';
    }else{
      this.type = 'delete'
    }
  }

}
