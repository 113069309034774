import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedNumber',
  pure: false
})
export class LocalizedNumberPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private defaultLocale: string,
    private decimalPipe: DecimalPipe,
    private translate: TranslateService
  ) {}

  transform(value: number | string, format?: string): string {
    const currentLang = this.translate.currentLang || this.translate.defaultLang || this.defaultLocale;
    return this.decimalPipe.transform(value, format, currentLang);
  }
}