import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'core-loading-container',
  templateUrl: './core-loading-container.component.html',
  styleUrls: ['./core-loading-container.component.css']
})
export class CoreLoadingContainerComponent implements OnInit {

  @Input() public loading : boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
