import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-core-spinner',
    templateUrl: './core-spinner.component.html',
    styleUrls: ['./core-spinner.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CoreSpinnerComponent implements OnInit {

    @Input('size') public size: number;
    constructor() { }

    ngOnInit(): void { }

}
