import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpRequestService } from '@core/services/http-request.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from 'app/pages/security/user/user.model';
import { Role } from '../models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private httpRequest: HttpRequestService,
    private jwtHelper: JwtHelperService,
  ){
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  hasValidToken() {
    
    try{
      console.log('has valid token call', this.jwtHelper.isTokenExpired())
      return !this.jwtHelper.isTokenExpired();
      
    }catch(error){
      console.log('erreuree', error)
      return null
    }
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.us_role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.us_role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(data : any) {
    return this.httpRequest
      .post(`/auth/login`, data)
      .pipe(
        map(response => {
          
          let user = response.user
          //let token = response.token

          console.log('user', user)
          if (user) {
              //localStorage.setItem('access_token', token);
              localStorage.setItem('user', JSON.stringify(user));
              this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
}
