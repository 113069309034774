import { Injectable } from '@angular/core';
import { CoreMask } from '@core/types/core-mask';
import { TranslateService } from '@ngx-translate/core';

export interface Locale {
  lang: string;
  data: Object;
}

@Injectable({
  providedIn: 'root'
})
export class CoreTranslationService {

  constructor(public _translateService: TranslateService) {

  }

  translate(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach(locale => {
      this._translateService.setTranslation(locale.lang, locale.data, true);
    });
  }

  getTranslatedValue(value: string) : any {
    return this._translateService.instant(value.toUpperCase());
  }
  
  getCurrentLanguage(){
    return this._translateService.currentLang || 'fr';
  }
  
  getDateFormat(){
    switch ((this._translateService.currentLang || 'fr')) {
      case 'en':
        return 'Y-m-d';
        default:
          return 'd/m/Y';
    }
  }

  getCoreMask(phoneNumberLength: number = 10){
    let phoneMask: string = "";
    // CHECK_CODE (valeur impaires)
    for (let i = 1; i <= Math.floor(phoneNumberLength/2); i++) {
      if (i == 1){
        phoneMask += (phoneNumberLength%2)?"000 " : "00 ";
      }else{
        phoneMask += "00 ";
      }
    }
    phoneMask = phoneMask.trim();

    let coreMask: CoreMask;
    switch ((this._translateService.currentLang || 'fr')) {
      case 'en':
        coreMask = {
          thousandSeparator : ',',
          phoneNumberMask: phoneMask
        }
        return coreMask;
        default:
          coreMask = {
            thousandSeparator: ' ',
            phoneNumberMask: phoneMask
          };
        return coreMask
    }
  }

  getThousandsSeparator(){
    switch ((this._translateService.currentLang || 'fr')) {
      case 'en':
        return ',';
        default:
          return ' ';
    }
  }
}
