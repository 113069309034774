<div style="padding: 0.375rem 0.75rem;" *ngIf="loading; else actionBlock">
  <div class="spinner-border spinner-border-sm text-center text-primary"  role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<ng-template #actionBlock>
  <ng-content></ng-content>
</ng-template>

