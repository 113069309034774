import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError, Observable } from "rxjs";
import { AlertService } from "./alert-message.service";
import { environment } from "environments/environment";
import { HTTP_BAD_REQUEST } from "@core/types/http-response-code";

const URL: string = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root'
})
export class HttpRequestService {

    constructor(
        private alertService: AlertService,
        private httpClient: HttpClient
    ) {}

    private getFullUrl(url : string){
        return `${URL}${url}`
    }

    handleError(error : any){
        if(error.status == HTTP_BAD_REQUEST ){
            console.log('erroer')
            return throwError(error.error.message)
        }
        return throwError("Une erreur est survenue lors du traitement")
    }

    // Output api error message
    handleServiceError(err) {
        this.alertService.errorAlert(err);
    }

    // Output api success message
    handleServiceSuccess(message) {
        this.alertService.successAlert(message);
    }

    // handler whenever an error occurs
    public handleErrorOld(httpError: HttpErrorResponse) {
        let errorMessage: string = 'Une erreur s\'est produite';
        if (httpError.error instanceof ErrorEvent) {
            errorMessage = `${httpError.error.message}`;
        }
        else {
            (httpError.status !== 404) ? errorMessage = httpError.error.error : errorMessage = "Erreur interne du serveur";
        }
        return throwError(errorMessage);
    }
    
    public get(url: string) : Observable<any>{
        return this.httpClient.get<any>(this.getFullUrl(url))
    }

    public post(url: string, datas : any) : Observable<any>{
        return this.httpClient.post<any>(this.getFullUrl(url), datas)
    }

    public put(url : string, data : any) : Observable<any> {
        return this.httpClient.put(this.getFullUrl(url), data)
    }
    
    public delete(url: string) : Observable<any> {
        return this.httpClient.delete(this.getFullUrl(url))
    }
}