export const locale = {
  lang: 'fr',
  data: {
    MENU: {
      DASHBOARD: 'Tableau de bord',
      SETTINGS_SECTION: 'Paramètres',
      MAPS: 'Géolocalisation',
      COUNTRIES: 'Pays',
      CITIES: 'Villes',
      DISTRICTS: 'Quartiers',
      SERVICES : 'Services',
      SERVICE_CATEGORY: "Catégories",
      SERVICE_SUB_CATEGORY: "Sous Catégories",
      NOTIFICATION: 'Notifications',
      SECURITY: 'Sécurité',
      USER: 'Utilisateurs',
      USERTYPE: 'Types utilisateur',
      SERVICE_UNITY : 'Unités',
      EXPLOITATION_SECTION : 'Exploitation',
      
      // EXPLOITATION
      CRM : 'CRM',
      CLIENTS : 'Clients',
      VENDORS : 'Vendeurs',
      ACCOUNT_DELETION_REQUESTS : 'Suppression de comptes',
      BOOKINGS: 'Réservations',
      BOOKING_CREATE : 'Creer une resa',
      BOOKING_LIST : 'Liste des resas',
      BOOKING_MARKS : 'Evaluations resas',
      CONTACT_REQUESTS : 'Demandes contacts',
      BOOKING_REQUESTS : 'Demandes resas',
      COUPONS: 'Coupons'

    }
  }
}
