<div class="modal-header">
  <h6 class="modal-title" id="modal-title">
    <span *ngIf="type=='delete'">Suppression d'un élément</span>
    <span *ngIf="type=='state'">Changement d'etat d'un élément</span>
  </h6>
  <button type="button" class="close" (click)="modal.dismiss('Annuler')" aria-label="Close">
    <span aria-hidden="true">&times;</span>
</button>
</div>

<div class="modal-body">

  <ng-container *ngIf="type=='state'">
    <p>
      <strong>Voulez vous vraiment changer l'etat de cet élément ?</strong>
    </p>
    <p>
      <span class="text-danger">Cette action est irreversible</span>
    </p>
  </ng-container>

  <ng-container *ngIf="type=='delete'">
    <p>
      <strong>Voulez vous vraiment supprimer cet élément ?</strong>
    </p>
    <p>
      Toutes les informations reliées a cet élément seront supprimées
      <span class="text-danger bold">Cette action est irreversible</span>
    </p>
  </ng-container>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.close()">Annuler</button>
  <button type="button" class="btn btn-danger" (click)="modal.close(true)">Oui</button>
</div>
