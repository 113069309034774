import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-core-alert-bar',
    templateUrl: './core-alert-bar.component.html',
    styleUrls: ['./core-alert-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CoreAlertBarComponent implements OnInit {

    @Input('errorMessage') public errorMessage: string;
    @Output() onClosed : EventEmitter<any> = new EventEmitter()
    constructor() { }

    handleClose(){
        
        this.onClosed.emit()
    }

    ngOnInit(): void { }

}