import { Component, OnInit } from '@angular/core'

import { locale as en } from './i18n/en'
import { locale as fr } from './i18n/fr'

import { CoreTranslationService } from '@core/services/translation.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public contentHeader: object

  /**
   *
   * @param {CoreTranslationService} _coreTranslationService
   */
  constructor(private _coreTranslationService: CoreTranslationService) {
    this._coreTranslationService.translate(fr, en)
    
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {

    this.contentHeader = {
      headerTitle: this._coreTranslationService.getTranslatedValue('SAMPLE.DASHBOARD'),
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          /*{
            name: this._coreTranslationService.getTranslatedValue('SAMPLE.DASHBOARD'),
            isLink: true,
            link: '/'
          },
          {
            name: this._coreTranslationService.getTranslatedValue('SAMPLE.DASHBOARD'),
            isLink: false
          }*/
        ]
      }
    }
  }
}
