import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-core-modal',
    templateUrl: './core-modal.component.html',
    styleUrls: ['./core-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CoreModalComponent implements OnInit {

    @Input('title') public title: string;
    @Input('footer') public footer: boolean;
    @Input('header') public header: boolean;
    constructor(public modal: NgbActiveModal) { }

    ngOnInit(): void { }

}